import React from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home2 } from "./home.js"
import { Home } from "./landingpagementorship.js";
import { Layout } from "./layout.js"
import { ParallaxProvider } from "react-scroll-parallax";
import { Landing_analiseperfil } from "./landing_analiseperfil.js";
import { Landing_doityourselfie } from "./landing_doityourselfie.js";
import { Landing_Contandostories } from "./landing_contandostories.js";
import { Service } from "../component/services.js";
import { LojinhadaKika } from "./lojinhadakika.js";
import { Pag_Aprovado } from "./pagaprovado.js";
import { Pag_emprocessamento } from "./pagemprocessamento.js";
import { Gifs } from "./gifs.js";
import { Landing_conexoes } from "./landing_conexoes.js";
import { StoriesModule } from "./stories_module.js";
// import NoPage from "./pages/NoPage";

export function App() {

  return (
    <ParallaxProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        {/* <Route index element={<Home />} />  ORIGINAL ANTERIOR - VOLTAR A VERSÃO APÓS PERIODO DE MENTORIA CONEXÕES */}
        <Route index element={<Landing_doityourselfie />} />

        <Route path="cursoconsultoriakikapeppermint" element={<Home />} />
        <Route path="landing_analiseperfil" element={<Landing_analiseperfil />} />
        <Route path="landing_doityourselfie" element={<Landing_doityourselfie />} />
        <Route path="landing_contandostories" element={<Landing_Contandostories />} />
        <Route path="conexoes" element={<Landing_conexoes />} />
        <Route path="lojinhadakika" element={<LojinhadaKika />} />
        <Route path="pagaprovado" element={<Pag_Aprovado />} />
        <Route path="pagemprocessamento" element={<Pag_emprocessamento />} />
        <Route path="service" element={<Service />} />
        <Route path="gifs" element={<Gifs />} />
        <Route path="stories" element={<StoriesModule />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
    </ParallaxProvider>
  );
}


