import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import "../styles/landingpage_doityourselfie.scss";
import "../styles/landing_analiseperfil.css";
import { MercadoPagoButton } from "../component/mpbutton.js";
import emailjs from "@emailjs/browser";
import { AiOutlineCamera, AiFillFileText } from "react-icons/ai";
import { FaBook, FaWhatsapp } from "react-icons/fa";
import { GiTripod } from "react-icons/gi";

export const Landing_doityourselfie = () => {
  const [paymodal, SetPaymodal] = useState(false);
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();
  const [dataform, SetDataform] = useState({
    user_name: "",
    user_email: "",
    user_celular: "",
    user_pais: "",
    user_redesocial: "",
  });
  const [alertmessage, SetAlertMessage] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 22)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 16)
      : (sizeremcurso.current = 16 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "YYH8R-60Dx8z_9NQI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    SetPaymodal(!paymodal);
    console.log("teste");
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#db2548" }}>
        <div
          className="text-white d-flex flex-lg-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className=""
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    className="p-2 mt-2"
                    style={{ fontFamily: "Shorelines", color: "#db2548" }}
                    id="title"
                  >
                    [kika PeppeminT]
                  </div>
                </Link>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <div>
            <img
              className="img-fluid"
              style={{ maxWidth: "400px", width: "100%", height: "auto" }}
              src="doityourselfie3.PNG"
              alt="Imagem"
            />
          </div>

          <h2
            className="container mb-4 mt-5 text-white text-start"
            id=""
            style={{ letterSpacing: "1.0px" }}
          >
            Quantas vezes você já quis uma boa foto sua, mas teve que pedir pra
            outras pessoas? Ou pior, pedir pra alguém sem paciência e talento
            pra fotografia? Chegou a hora de mudar isso.
          </h2>
          <h2
            className="container mb-4 mt-5 text-white text-start"
            id=""
            style={{ letterSpacing: "1.0px" }}
          >
            E se você tivesse o passo a passo, o "do it yourself", pra você
            mesma fazer suas fotos? Com a Masterclass Do It YourSelfie, você vai
            aprender a ser fotograficamente independente — capturando sua
            própria imagem como uma profissional, sem precisar de mais ninguém.
          </h2>
          <h2
            className="container mb-4 mt-5  text-white text-start"
            id=""
            style={{ letterSpacing: "1.0px" }}
          >
            Fazer selfies é um empoderamento que vai te permitir não só a obter
            fotos do jeito que você deseja. Ao aprender a se autorregistrar,
            você ganha liberdade para capturar belas imagens no seu dia a dia.
          </h2>

          {/* <h4 className="container mb-4 mt-4" id="sizeabletext">
            COM A CONSULTORIA "DO IT YOUR SELFIE" VOCÊ VAI USAR SELFIES E VLOGS
            PARA HUMANIZAR SEU PERFIL,{" "}
              E SE TORNAR MAIS PRESENTE NAS SUAS REDES SOCIAIS.
            </font>
          </h4> */}

          <div className="container text-center">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdJ574zTGWtsNzXxHQtEmh7VtqecbjyAj2m9JoKN53LzD9B2A/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn btn-lg mt-5 mb-4 text-white"
                style={{
                  backgroundColor: "#DB9E25",
                  fontWeight: "bold",
                  textShadow: "2px 2px 4px #000",
                }}
              >
                COMPRE AGORA A MASTERCLASS "DO IT YOUR SELFIE"
              </button>
            </a>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {paymodal === false ? (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex  flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4"
                  id="exampleModalLabel"
                  style={{ textAlign: "justify" }}
                >
                  Antes de prosseguir precisamos de algumas informações de
                  contato. Após o preenchimento clique em "Seguir para
                  Pagamento".
                </h6>
                {alertmessage === true ? (
                  <h6
                    className="text-center"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Você precisa preencher todos os dados antes de seguir para
                    pagamento!
                  </h6>
                ) : (
                  ""
                )}
                <div className="">
                  <form
                    className="container-fluid"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        Nome:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="user_name"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_name: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        Celular:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Celular"
                        aria-label="Celular"
                        aria-describedby="basic-addon1"
                        name="user_celular"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_celular: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        e-mail:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="e-mail"
                        aria-label="Useremail"
                        aria-describedby="basic-addon1"
                        name="user_email"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_email: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        País:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="País"
                        aria-label="Userpais"
                        aria-describedby="basic-addon1"
                        name="user_pais"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_pais: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        @ Rede Social
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="@ da sua Rede Social"
                        aria-label="Userredesocial"
                        aria-describedby="basic-addon1"
                        name="user_redesocial"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_redesocial: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>

                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn text-white p-2"
                        data-bs-dismiss="modal"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          SetAlertMessage(false);
                        }}
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        className="btn text-white p-2"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          console.log(dataform);
                          if (
                            dataform.user_redesocial !== "" &&
                            dataform.user_pais !== "" &&
                            dataform.user_email !== "" &&
                            dataform.user_celular !== "" &&
                            dataform.user_name !== ""
                          ) {
                            console.log("pode enviar");
                            sendEmail();
                          } else {
                            console.log("NÃO pode enviar");
                            SetAlertMessage(true);
                          }
                        }}
                      >
                        Seguir para Pagamento
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    SetPaymodal(!paymodal);
                    console.log("fechar");
                  }}
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex  flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="p-2 mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4 text-center"
                  id="exampleModalLabel"
                >
                  Faça o pagamento abaixo pelo Mercado Pago ou por Cartão de
                  Crédito clicando no botão "Pagar".
                </h6>
                <div className="">
                  <div
                    className="text-center mt-1 mb-1 p-3 rounded text-white"
                    style={{ backgroundColor: "#db2548" }}
                  >
                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        color: "#db2548",
                        fontWeight: "bold",
                      }}
                    >
                      Consultoria Do It YourSelfie
                    </h3>

                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#db2548",
                      }}
                    >
                      R$ 177,00
                    </h3>
                    <span>
                      Esse valor pode ser parcelado com juros do cartão
                    </span>
                  </div>

                  <div className="modal-footer justify-content-center">
                    <button
                      type="button"
                      className="btn text-white"
                      style={{ backgroundColor: "#db2548" }}
                      onClick={() => {
                        SetPaymodal(!paymodal);
                        console.log("teste");
                      }}
                      data-bs-dismiss="modal"
                    >
                      Sair
                    </button>
                    <div>
                      <MercadoPagoButton
                        src={
                          "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js"
                        }
                        preferenceId={
                          "1338322951-44b22bf4-a935-465e-b8bf-b826af95e4a9"
                        }
                        source={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex row flex-lg-wrap justify-content-center">
        <div className="d-flex justify-content-center mb-4 mt-4">
          <div className="">
            <div className="p-1 text-center">
              <h2 className="container p-2" id="" style={{ color: "#db2548" }}>
                Sabia que selfies podem ser usadas também na sua criação de
                conteúdo?{" "}
              </h2>

              <h4
                className="container p-2 text-start"
                id=""
                style={{ color: "#db2548" }}
              >
                Para reforçar sua imagem como parte da sua marca, você precisa
                estar presente nas suas redes digitais. Ao gerar mais conexão
                com seus seguidores você tem a possibilidade de vender muito
                mais!{" "}
              </h4>

              <h4
                className="container p-4 fw-bold"
                id=""
                style={{ color: "#db2548" }}
              >
                COM A MASTERCLASS "DO IT YOUR SELFIE" VOCÊ VAI APRENDER A FAZER
                SELFIES PROFISSIONAIS, QUE PODEM SE USADAS EM DIVERSOS ASPECTOS
                DA SUA VIDA. INCLUINDO CRIAR CONTEÚDO PARA SUAS REDES DIGITAIS.{" "}
              </h4>

              <h4
                className="container p-2 text-start"
                id=""
                style={{ color: "#db2548" }}
              >
                Na MASTERCLASS DO IT YOURSELFIE eu ensino todo o passo a passo,
                a técnica fotográfica e aspectos narrativos para criar fotos de
                si mesma, como uma pessoal profissional da fotografia faria.
                Traduzindo sua essência e personalidade de forma mais atrativa e
                cativante.{" "}
              </h4>

              <h4
                className="container p-2 text-start"
                id=""
                style={{ color: "#db2548" }}
              >
                Dia 27/02 às 19h (horário de Lisboa) 16h (horário de Brasília)
                Você vai ter a base para fazer qualquer tipo de auto registro,
                otimizando a utilização da luz, ângulo, enquadramento, pose,
                linguagem corporal e cenário, para que você obtenha imagens de
                si mesma, de forma rápida e prática, no seu cotidiano.{" "}
              </h4>
            </div>
            <div className="container text-center">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdJ574zTGWtsNzXxHQtEmh7VtqecbjyAj2m9JoKN53LzD9B2A/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn btn-lg mt-5 mb-4 text-white"
                  style={{
                    backgroundColor: "#DB9E25",
                    fontWeight: "bold",
                    textShadow: "2px 2px 4px #000",
                  }}
                >
                  COMPRE CLICANDO AQUI
                </button>
              </a>
            </div>
          </div>
        </div>

        <h1 className="p-2 mt-2 display-5 text-center">
          <b className="" style={{ color: "#db2548" }}>
            {" "}
            &nbsp; Pra quem é a Masterclass "Do It YourSelfie"?
          </b>
        </h1>

        <div className="container justify-content-center gap-3 p-2">
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você cansou de só ter selfies segurando o celular e quer
                    elevar o nível dos seus auto registros.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Para você que quer aprender a fotografar a si mesma em
                    diversas situações, como se tivesse um profissional 24h com
                    você.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você quer criar imagens mais criativas e cativantes até
                    em situações pouco favoráveis.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você quer se conectar mais com sua audiência nas suas
                    redes sociais mostrando que você faz parte da sua marca.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você quer ter um acervo autoral variado e inspirador de
                    boas fotos suas pra usar na sua criação de conteúdo.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Para você que não quer perder seu tempo pedindo ajuda para
                    pessoas que não tem talento nem boa vontade para fazer fotos
                    suas pro seu negocio ou qualquer outra situação cotidiana.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <h2 className="p-2 mt-3 display-5 text-center">
            <b className="" style={{ color: "#db2548", fontSize: "30px" }}>
              {" "}
              &nbsp; E mesmo que você não trabalhe utilizando as redes sociais
              pra divulgar sua marca.
            </b>
          </h2>

          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você é uma mulher ou mãe (ou os dois) que nunca aparece
                    nas fotos, pois você é a única pessoa que faz imagens para
                    todo mundo.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você deseja ter registros bonitos em passeios, viagens,
                    no cotidiano, e também junto da família filhos e amigos,
                    então a masterclass também é pra você.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você quer economizar dinheiro diminuindo a necessidade de
                    vários ensaios profissionais por ano.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item align-items-center"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-3"
                    style={{ color: "#DB9E25", fontSize: "1.8rem" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "0", textAlign: "left" }}
                  >
                    Se você quer otimizar o tempo do(a) seu(sua) fotógrafo(a)
                    sabendo exatamente o que deseja no seu ensaio.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex row flex-lg-wrap justify-content-center mt-4"
        style={{ backgroundColor: "#fff" }}
      >
        <h1 className="p-2 text-center mt-4">
          <span className="" style={{ color: "#db2548" }}>
            {" "}
            Vantagens da Masterclass "Do It YourSelfie":
          </span>
        </h1>

        <div className="container justify-content-center gap-3 p-4">
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded align-items-center"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <AiOutlineCamera
                    className="me-3 text-white"
                    style={{ fontSize: "3.0rem" }}
                  />
                  <p
                    className="ms-3 text-white mb-0"
                    id=""
                    style={{ textAlign: "left" }}
                  >
                    Além de ensinar o meu método para você fazer fotos de si
                    mesma, você terá direito a todo material didático utilizado
                    durante a masterclass.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded align-items-center"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <FaBook
                    className="me-3 text-white"
                    style={{ fontSize: "3.5rem" }}
                  />
                  <p
                    className="ms-3 text-white mb-0"
                    id=""
                    style={{ textAlign: "left" }}
                  >
                    Após a aula, eu vou te enviar um material ainda mais
                    completo, pra você relembrar a teoria e praticar, que conta
                    também com exemplos e imagens ilustrativas pra facilitar a
                    compreensão.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded align-items-center"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <AiFillFileText
                    className="me-3 text-white"
                    style={{ fontSize: "5rem" }}
                  />
                  <p
                    className="ms-3 text-white mb-0"
                    id=""
                    style={{ textAlign: "left" }}
                  >
                    Após a aula você ainda terá acesso a um grupo de WhatsApp
                    exclusivo das alunas, por um período de 2 semanas
                    inteirinhas! Para tirar dúvidas e mostrar como anda o
                    processo de fazer selfies que serão analisadas e comentadas
                    por mim.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded align-items-center"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <FaWhatsapp
                    className="me-3 text-white"
                    style={{ fontSize: "3.1rem" }}
                  />
                  <p
                    className="ms-3 text-white mb-0"
                    id=""
                    style={{ textAlign: "left" }}
                  >
                    No meu método você não precisa de mais nada além do seu
                    celular. Então até mesmo se não tiver um tripé, isso não
                    será um problema.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              {/* Primeiro card */}
              <div
                className="col-12 col-md-3 card justify-content-center mb-3 p-0 mx-4"
                style={{ border: "2px solid #db2548", height: "120px" }}
              >
                <div className="card-text " style={{ height: "100%" }}>
                  <ul
                    className="list-group list-group-flush mx-auto rounded d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <li
                      className="list-group-item text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Masterclass com 2h de duração
                    </li>
                  </ul>
                </div>
              </div>

              {/* Segundo card */}
              <div
                className="col-12 col-md-3 card justify-content-center mb-3 p-0 mx-4"
                style={{ border: "2px solid #db2548", height: "120px" }}
              >
                <div className="card-text " style={{ height: "100%" }}>
                  <ul
                    className="list-group list-group-flush mx-auto rounded d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <li
                      className="list-group-item text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Acesso ao material utilizado durante a aula em .pdf
                    </li>
                  </ul>
                </div>
              </div>

              {/* Terceiro card */}
              <div
                className="col-12 col-md-3 card justify-content-center mb-3 p-0 mx-4"
                style={{ border: "2px solid #db2548", height: "120px" }}
              >
                <div className="card-text " style={{ height: "100%" }}>
                  <ul
                    className="list-group list-group-flush mx-auto rounded d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <li
                      className="list-group-item text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Acesso ao grupo exclusivo de alunas para tirar dúvidas e
                      ter a análise das suas selfies pelo período de duas
                      semanas imediatamente após a aula
                    </li>
                  </ul>
                </div>
              </div>

              {/* Quarto card */}
              <div
                className="col-12 col-md-3 card justify-content-center mb-3 p-0 mx-4"
                style={{ border: "2px solid #db2548", height: "120px" }}
              >
                <div className="card-text " style={{ height: "100%" }}>
                  <ul
                    className="list-group list-group-flush mx-auto rounded d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <li
                      className="list-group-item text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Material didático extra que será enviado após o período de
                      tirar dúvidas no grupo
                    </li>
                  </ul>
                </div>
              </div>

              {/* Quinto card */}
              <div
                className="col-12 col-md-3 card justify-content-center mb-3 p-0 mx-4"
                style={{ border: "2px solid #db2548", height: "120px" }}
              >
                <div className="card-text " style={{ height: "100%" }}>
                  <ul
                    className="list-group list-group-flush mx-auto rounded d-flex align-items-center justify-content-center"
                    style={{ height: "100%" }}
                  >
                    <li
                      className="list-group-item text-center"
                      style={{ fontWeight: "bold" }}
                    >
                      Reunião online através de vídeo chamada dia 27/02 às 19h
                      (🇵🇹) 16h (🇧🇷)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container col-12 col-sm-4 mt-5 mb-2 border"
          style={{ backgroundColor: "white" }}
        >
          <h1 className="p-2 text-center mt-4">
            <span className="" style={{ color: "#db2548" }}>
              {" "}
              Tudo isso por apenas:
            </span>
          </h1>
          <div
            className="text-center mt-1 mb-4 p-3 rounded text-white"
            style={{ backgroundColor: "#db2548" }}
          >
            <h3
              className="rounded p-3 fs-3"
              style={{
                backgroundColor: "#fff",
                color: "#db2548",
                fontWeight: "bold",
              }}
            >
              De <strike>35€ (210 reais)</strike> por APENAS 22€ (132 reais)
            </h3>
            <p className="text-white">
              Alunas do Brasil parcelamento em até 3x ( juros do cartão )
            </p>
            <p className="text-white">
              Desconto extra de 15% para quem for da comunidade Empreendedoras
              pelo Mundo no WhatsApp, utilizando o cupom KIKAEPM15
            </p>

            <div className="container text-center">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdJ574zTGWtsNzXxHQtEmh7VtqecbjyAj2m9JoKN53LzD9B2A/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="btn btn-lg mt-3 mb-4 text-white"
                  style={{
                    backgroundColor: "#DB9E25",
                    fontWeight: "bold",
                    textShadow: "2px 2px 4px #000",
                  }}
                >
                  COMPRE CLICANDO AQUI
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <h4 className="container mb-4 mt-4" id="sizeabletext">
            OLÁ, SOU KIKA PEPPERMINT
          </h4>

          <div className="">
            <img
              src="squarekika.jpg"
              alt=""
              style={{ width: sizerem.current, borderRadius: "50%" }}
              id="peppermint"
            />
          </div>

          <h6 className="container mb-2 mt-4 text-white" id="sizeabletext">
            Sou graduada em produção cultural, fotógrafa, tenho formação em
            cinema e linguagem audiovisual. Sou criadora de conteúdo sobre
            storytelling e narrativas visuais com o objetivo de ajudar mulheres
            a produzir conteúdo de forma atrativa, cativante e envolvente.
          </h6>
        </div>
      </div>
      <div
        className="d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div
          className="container col-lg-8 border rounded-4"
          style={{ backgroundColor: "#fff" }}
        >
          <h1 className="m-5 display-5 text-center">
            <b className="" style={{ color: "#db2548" }}>
              {" "}
              O que as minhas alunas tem achado sobre essa Consultoria
            </b>
          </h1>

          <div className="container justify-content-center">
            <div
              className="d-flex row justify-content-center mb-4"
              id="containerteste"
            >
              {[
                <img src="depo_doityourselfie1.jpg" alt="" />,
                <img src="depo_doityourselfie2.jpg" alt="" />,
                <img src="depo_doityourselfie4.jpg" alt="" />,
                <img src="depo_doityourselfie5.jpg" alt="" />,
                <img src="depo_doityourselfie6.jpg" alt="" />,
                <img src="depo_doityourselfie7.jpg" alt="" />,

                <img src="depo_doityourselfie9.jpg" alt="" />,


                <img src="depdoityourselfie3.png" alt="" />,


              ].map((num, index) => (
                <div
                  key={index}
                  className={`item ${
                    activeIndex === index ? "active rounded" : "rounded"
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {num}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container text-center">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdJ574zTGWtsNzXxHQtEmh7VtqecbjyAj2m9JoKN53LzD9B2A/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              type="button"
              className="btn btn-lg mt-5 mb-4 text-white"
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
              }}
            >
              CLIQUE AQUI E COMPRE AGORA
            </button>
          </a>
        </div>
      </div>

      <div className="container d-flex flex-wrap justify-content-center">
        <div className="p-4 d-flex">
          <img
            className=""
            src="unnamed222.jpg"
            alt=""
            style={{
              width: "17rem",
              borderRadius: "50%",
              margin: "auto",
              borderRadius: "50%",
            }}
            id="peppermint"
          />
        </div>

        <div className="p-0 " style={{ margin: "auto" }}>
          <h4 className="text-center">
            Caso você tenha alguma dúvida adicional mande um email para
          </h4>

          <h4 className="text-center p-0 mb-4">
            <font className="text-center p-0" style={{ fontWeight: "bold" }}>
              <a
                href="mailto:kika@kikapeppermint.com"
                style={{ textDecoration: "none", color: "#db2548" }}
              >
                kika@kikapeppermint.com
              </a>
            </font>
          </h4>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#db2548" }}
      >
        <div className="col-md-5 d-flex text-white ">
          <span className="">© 2025 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
      <div className="container text-center mt-2 mb-2">
        <span style={{ color: "#171717", fontSize: "14px" }}>
          Developed and Supported by{" "}
          <a
            href="https://www.codeplusmore.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            CodePlusMore
          </a>
        </span>
      </div>
    </div>
  );
};
